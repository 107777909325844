import { GetServerSideProps } from 'next';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { PostgrestResponse } from '@supabase/supabase-js';

import { PageDetail } from '@components/pageDetail/PageDetail';
import { Seo } from '@components/seo/Seo';

import { isProduction } from '@common/utils/environment';
import { handleError } from '@common/utils/handleError';
import {
	getEventsCollections,
	serverSideWithSiteData,
} from '@common/utils/page';
import { OFF_SITE_DICE_TAG } from '@data/constants';
import { queryFromDato } from '@data/datocms/client';
import { homePageQuery } from '@data/datocms/queries';
import { mapEventData } from '@data/dice/mappers';
import {
	DiceEvent,
	DiceEventResponse,
	PageResponse,
	SiteData,
} from '@interfaces';

interface HomePageProps extends PageResponse {
	justAnnouncedEvents: DiceEvent[] | null;
	serverNow: number;
}

const HomePage = ({
	page,
	programme,
	justAnnouncedEvents,
	serverNow,
	site,
}: HomePageProps & SiteData) => {
	return (
		<>
			<Seo metaTagData={[...page.seoMetaTags, ...site.faviconMetaTags]} />
			<PageDetail
				{...page}
				isHomePage
				justAnnouncedEvents={justAnnouncedEvents}
				programmeLabels={programme}
				serverNow={serverNow}
			/>
		</>
	);
};

// This uses server side rendering in preparation for using pageQuery and shareable previews
export const getServerSideProps: GetServerSideProps =
	serverSideWithSiteData<HomePageProps>(async ({ res }) => {
		const homePageResponse = await queryFromDato<PageResponse>({
			query: homePageQuery,
		});

		const page = homePageResponse?.page;
		const programme = homePageResponse?.programme;

		if (!page || !programme) {
			return {
				notFound: true,
			};
		}

		// Just announced events
		let justAnnouncedEvents: DiceEvent[] | null = null;

		const supabaseClient = createBrowserSupabaseClient();

		const { error, data }: PostgrestResponse<{ data: DiceEventResponse }> =
			await supabaseClient
				.rpc('get_events_v4', {
					just_announced: true,
					exclude_tags: [OFF_SITE_DICE_TAG],
				})
				.select('data')
				.order('data->announcement_date', { ascending: false });

		if (error) {
			handleError(error);
		}

		if (data) {
			justAnnouncedEvents = data.map(({ data: eventData }) => {
				return mapEventData(eventData);
			});
		}

		await getEventsCollections(page);

		if (isProduction()) {
			res.setHeader(
				'Cache-Control',
				'public, s-maxage=300, stale-while-revalidate=60',
			);
		}

		return {
			props: {
				page,
				programme,
				justAnnouncedEvents,
				serverNow: Date.now(),
			},
		};
	});

export default HomePage;
